import { Timestamp } from '@firebase/firestore-types';
import { LanguageUppercase } from '../languages';
import { LawScope } from '../lawScope';
import { DecisionRequestDetails } from './decisionRequest';

export enum DecisionStatus {
    NOT_TREATED = 'notTreated',
    TO_KEEP = 'toKeep',
    TREATED = 'treated',
}

/**
 * @field importedAt - Import date
 * @field importedBy - List of requests that imported this decision
 * @field requestDetails - Details of the request that originated the decision import, used to preserve the original request if it is modified or deleted in the future.
 * @field sourceId - Identifier of the source from which the decision was imported
 * @field treesImportedBy - List of trees to which the import requests belong to
 * @field treesVisibleIn - List of trees in which the decision is visible
 * @field visibleIn - List of requests where the decision is visible
 */
export interface ImportDetails<D extends Timestamp | Date> {
    importedAt: D;
    importedBy: string[];
    requestDetails: DecisionRequestDetails;
    sourceId: string;
    treesImportedBy: string[];
    treesVisibleIn: string[];
    visibleIn: string[];
}

export const decisionStatusTranslation: Record<DecisionStatus, string> = {
    [DecisionStatus.TO_KEEP]: 'À conserver',
    [DecisionStatus.NOT_TREATED]: 'À traiter',
    [DecisionStatus.TREATED]: 'Traitée',
};

export interface DecisionJury {
    names: string[];
    position: string;
}

export type SplittedCases = [string[], string[]];

/**
 * @field uniqueIds - Object of unique identifiers of a decision, cas serve as a key to find duplicates
 */
interface DecisionCore<D extends Timestamp | Date> {
    analysisInDecisionUpdatedAt: D;
    biblio: string[];
    city: string;
    createdAt: D;
    decisionStatus: DecisionStatus;
    decisionType: string | string[];
    defendant: string[];
    fullText?: string;
    importDetails?: ImportDetails<D>;
    isFormatVerified: boolean;
    juridictionName: string;
    juridictionRoom: string;
    juridictionType: string;
    jury: DecisionJury[];
    language: LanguageUppercase;
    lawScopes: LawScope[];
    legislation: string[];
    note: string;
    number: string[];
    performedAt: D;
    plaintiff: string[];
    publicationStatus: string;
    result?: string;
    sameCase: string[];
    summary: string;
    title: string;
    uniqueIds: string[];
    updatedAt: D;
}

export type Decision = DecisionCore<Timestamp>;

export interface DecisionWithId extends Decision {
    id: string;
}

export type DecisionRaw = DecisionCore<Date>;
